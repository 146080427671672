<template>
  <v-app style="background-color: #b0bec5">
    <v-main>
      <v-dialog persistent v-model="loading" max-width="300">
        <v-card>
          <v-toolbar dense color="primary" dark
            >Cargando aplicación...
          </v-toolbar>
          <v-progress-linear color="white" indeterminate></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { cambiarAppService } from "./cambioApp.service";

export default {
  computed: {
    ...mapGetters(["appName", "redirect"]),
  },
  data() {
    return {
      loading: true,
      mensaje: "Cargando aplicación...",
    };
  },
  async mounted() {
    try {
      const token = this.$route.params.token;
      const serverResponse = await cambiarAppService(token);
      if (!serverResponse.ok) {
        this.$systemErrorMessage(serverResponse.mensaje);
          window.location.href = this.redirect;
      } else {
        const tokenSesion = serverResponse.tokenSesion;
        this.$store.dispatch("setSessionToken", tokenSesion);
        localStorage.setItem("agnus-token", tokenSesion);
        this.$router.push('/');
      }
    } catch (error) {
      window.history.back();
    }
  },
};
</script>
